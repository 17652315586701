
import { Watch, Mixins, Component, Vue, Ref, InjectReactive } from "vue-property-decorator";
import inputPrice from "@/utils/inputPrice";

import RegionData from "@/mixins/regionData";

import { cloneDeep, debounce } from "lodash";
import dayjs from "dayjs";

import { getUserList } from "@/api/userMember";
import { getNavList as getNavListApi } from "@/api/mini";
import {
  createStore,
  editStore,
  getSaasStoreList,
  authBanding as authBandingApi,
  bindingByStoreBindingNo,
} from "@/api/store";

import { Form } from "element-ui";
import { IHomeInstance } from "@/types/global";

import { bankNoReg } from "@/utils/regular";

const initFormData = {
  storeName: "",
  status: "",
  restDateList: [],
  ownerName: "",
  phone: "",
  storeInfo: "",
  saasStore: {},
  storeType: null,
  address: [],
  shipAddress: '',
  longAndLat: '',
  storeLong: 0,
  storeLat: 0,
  storePhone: '',
  storeMentor: 0,
  storeSupport: 0,
  mid: "",
  tid: "",
  mallEnable: false,
  mchntNo: "",
  screenSaver: true
};

@Component({
  components: {}
})
export default class StoreFill extends Mixins(RegionData) {

  @Ref("validateFormRef") readonly validateFormRef !: Form;
  @InjectReactive() HomeInstance !: IHomeInstance;

  storeInfo = JSON.parse(this.$route.query.storeInfo as string || JSON.stringify({}));

  regionData = [];

  storeTypeList = [];
  statusOptions = [
    { name: "营业中", val: 0 },
    { name: "歇业", val: 2 },
    { name: "选址中", val: 3 },
    { name: "建店中", val: 4 }
  ]

  saasStoreTypeOptions = [
    { label: "养身帮门店", value: "self" },
    { label: "第三方门店", value: "3rd" }
  ]

  authBanding = false;
  authCode = "";

  allUserList = [];
  dynamicTags = [];

  storeMemberList = [];

  allSaasStoreList = [];

  userLoading = false;

  mentorTag = null;
  supportTag = null;

  dialogVisible = false;

  saasStoreLoading = false;

  formData = {
    id: "",
    storeName: "",
    ownerName: "",
    phone: "",
    storeInfo: "",
    saasStore: {},
    storeType: null,
    status: "",
    restDateList: [],
    address: [],
    shipAddress: '',
    longAndLat: '',
    storeLong: 0,
    storeLat: 0,
    storePhone: '',
    storeMentor: 0,
    storeSupport: 0,
    source: "self",
    mid: "",
    tid: "",
    mallEnable: false,
    storeShowName: "",
    mchntNo: "",
    storeBindingNo: undefined,
    screenSaver: true
  };

  restDateListPickerOptions = {
    disabledDate(v) {
      return v.getTime() < new Date().getTime() - 86400000;
    }
  }


  rules = {};
  rulesForCreate = {
    storeType: [{ required: true, message: "请选择门店类型", trigger: "change" }],
    status: [{ required: true, message: "请选择门店状态", trigger: "change" }],
    storeName: [{ required: true, message: "请输入门店名称", trigger: "blur" }],
    ownerName: [{ required: true, message: "请输入门店负责人", trigger: "blur" }],
    address: [{ required: true, message: "请选择门店所在城市", trigger: "change" }],
    shipAddress: [{ required: true, message: "请输入门店具体地址", trigger: "blur" }],
    phone: [
      { required: true, message: "请输入11位数电话号码", trigger: "blur" },
    ],
    storePhone: [
      { required: true, message: "请输入门店电话", trigger: "blur" },
    ],
    longAndLat: [
      { required: true, message: "请填写门店经纬度", trigger: "blur" },
      { validator: this.validateLatAndLng, trigger: 'blur' }
    ],
    mid: [{ message: "请输入银联商户号", validator: this.checkBankNo, trigger: 'blur' }],
    tid: [{ message: "请输入终端号", validator: this.checkBankNo, trigger: 'blur' }],
    mchntNo: [{ message: "请输入待付商户号", validator: this.checkBankNo, trigger: 'blur' }],
    mallEnable: [{ type: "boolean", message: "请输入", trigger: 'blur' }]
  };
  rulesForEdit = {
    status: [{ required: true, message: "请选择门店状态", trigger: "change" }],
    storeName: [{ required: true, message: "请输入门店名称", trigger: "blur" }],
    ownerName: [{ required: true, message: "请输入门店负责人", trigger: "blur" }],
    phone: [
      { required: true, message: "请输入11位数电话号码", trigger: "blur" },
    ],
    longAndLat: [
      { validator: this.validateLatAndLng, trigger: 'blur' }
    ],
    mid: [{ message: "请输入银联商户号", validator: this.checkBankNo, trigger: 'blur' }],
    tid: [{ message: "请输入终端号", validator: this.checkBankNo, trigger: 'blur' }],
    mchntNo: [{ message: "请输入待付商户号", validator: this.checkBankNo, trigger: 'blur' }],
    mallEnable: [{ type: "boolean", message: "请输入", trigger: 'blur' }]
  };

  saveLoading = false;

  mounted() {
    this.getStoreTypeList();
    this.rules = this.storeInfo.id ? this.rulesForEdit : this.rulesForCreate;
    this.formatFormData();
  }

  storeBindingNoChange($event) {
    if (!$event) return;
    bindingByStoreBindingNo({ storeBingingNo: $event }).then((res) => {
      this.formData.source = "self";
      this.allSaasStoreList = [{ ...res.data, saaSStoreId: res.data.id, saaSStoreName: res.data.saasStoreName }];
      this.formData.saasStore = this.allSaasStoreList[0];
    })
  }

  checkBankNo(rule, value, callback) {
    const mallEnable = this.formData.mallEnable;
    if (!mallEnable) {
      if (!value?.length) {
        callback()
      }
    };
    return bankNoReg.test(value) ? callback() : callback(new Error());
  }

  formatFormData() {
    if (this.storeInfo.id > 0) {
      this.storeInfo.longAndLat = '';
      this.formData = cloneDeep(this.storeInfo);
      this.formData.storeBindingNo = this.formData.storeBindingNo ? this.formData.storeBindingNo : undefined;

      this.storeMemberList = cloneDeep(this.storeInfo.storeMemberList || []);
      this.formData.restDateList = this.storeInfo.restDateList?.map((el) => dayjs(el).startOf("day").toDate());
      this.formData.mallEnable = Boolean(this.formData.mallEnable);
      this.formData.screenSaver = Boolean(this.formData.screenSaver);

      // 还原门店管理员账号数据
      this.storeInfo.storeUserOwners &&
        this.storeInfo.storeUserOwners.forEach((user) => {
          let temp = user;
          temp.id = user.userId;
          // 过滤重复数据
          let duplicate = false;
          for (let tag of this.dynamicTags) {
            if (tag.id == temp.id) {
              duplicate = true;
              break;
            }
          }
          if (!duplicate) {
            this.dynamicTags.push(temp);
          }
      });

      // 还原门店督导和服务跟进人的账号数据
      this.mentorTag = this.storeInfo.storeMentorUser;
      this.supportTag = this.storeInfo.storeSupportUser;

      // 将地址字符串还原成数组，如果不是3级地址，则默认为空数组
      let addrComponents = this.formData.address && typeof this.formData.address === 'string' ? (this.formData.address as string)?.split(',') : [];
      this.formData.address = addrComponents.length == 3 ? addrComponents : [];

      // 将经纬度还原成字符串
      if (this.formData.storeLong && this.formData.storeLat) {
        this.formData.longAndLat = this.formData.storeLat + ',' + this.formData.storeLong;
      }
    }
    this.echoSaasStoreList(this.storeInfo);
  }


  // 编辑的时候，要给下拉框列表赋值，不然就是空白的
  echoSaasStoreList(row) {
    this.allSaasStoreList = [{ saaSStoreId: row.saasStoreId, saaSStoreName: row.saasStoreName, brandId: row.saasBrandId, zoneId: row.saasZoneId }];
    this.formData.saasStore = this.allSaasStoreList[0];
  }

  // 查询门店类型字典
  getStoreTypeList() {
    getNavListApi('store_type').then((res) => {
      let dists = res.data || [];
      let types = [];
      dists.forEach(item => {
        types.push({name: item.dictValue, val: item.id});
      });
      this.storeTypeList = types;
    });
  }

  restDateListListChange($event) {
    const _list = this.formData.restDateList?.sort((el1, el2) => {
      return el1?.getTime() - el2?.getTime()
    })
    this.formData.restDateList = cloneDeep(_list)
  }

  validateLatAndLng(rule, value, callback) {
    let isValid = false;
    if (value && value.indexOf(',') > 0) {
      let lngAndLat = value.split(',');
      if (lngAndLat.length == 2) {
        let lat = parseFloat(lngAndLat[0]);
        let lng = parseFloat(lngAndLat[1]);
        if (lat > 0 && lng > 0) {
          isValid = true;
        }
      }
    }

    if (!isValid && value) {
      callback(new Error('请复制正确的经纬度'));
    } else {
      callback();
    }
  }

  handleClose(cur) {
    let temp = this.dynamicTags;
    this.dynamicTags = temp.filter((item) => {
      return item.id != cur.id;
    });
  }

  handleClerkClose(cur) {
    let temp = this.storeMemberList;
    this.storeMemberList = temp.filter((item) => {
      return item.id != cur.id;
    });
  }
  handlerestDateListClose(cur,index) {
    this.formData.restDateList?.splice(index, 1)
  }

  getTimeString(time) {
    return dayjs(time)?.format("MM月DD日")
  }

  inputValue(val) {
    let phone = inputPrice(val, "number");
    this.formData.phone = phone || "";
  }

  inputStorePhone(val) {
    let phone = inputPrice(val, "number");
    this.formData.storePhone = phone || "";
  }

  getUserList(str) {
    if (typeof str == "object") {
      str = "";
    }
    this.userLoading = true;
    getUserList({
      phone: str
    })
      .then((res) => {
        this.userLoading = false;
        this.allUserList = res.data || [];
      })
      .catch((err) => {
        this.userLoading = false;
      });
  }
  setCurUser(cur) {
    // 手机号为空或者重复添加的数据将自动跳过
    if (!cur.phone || cur.phone.length == 0 || !cur.id) {
      return;
    } else {
      for (let user of this.dynamicTags) {
        if (user.id == cur.id) {
          return;
        }
      }
    }
    this.dynamicTags.push(cur);
  }

  setCurClerk(cur) {
    // 手机号为空或者重复添加的数据将自动跳过
    if (!cur.phone || cur.phone.length == 0 || !cur.id) {
      return;
    } else {
      for (let user of this.storeMemberList) {
        if (user.id == cur.id) {
          return;
        }
      }
    }
    this.storeMemberList.push(cur);
  }

  openTencentLBS() {
    let href = 'https://lbs.qq.com/getPoint/';
    window.open(href, "_blank");
    return true;
  }

  setStoreMentor(cur) {
    if (cur == null) {
      this.mentorTag = null;
      return;
    }
  // 手机号为空或者重复添加的数据将自动跳过
    if (!cur.phone || cur.phone.length == 0 || !cur.id) {
        return;
      }
    this.mentorTag = cur;
  }

  setStoreSupport(cur) {
    if (cur == null) {
      this.supportTag = null;
      return;
    }
    // 手机号为空或者重复添加的数据将自动跳过
    if (!cur.phone || cur.phone.length == 0 || !cur.id) {
      return;
    }
    this.supportTag = cur;
  }

  // 下拉查询SaaS系统绑定列表
  getSaasRemote = debounce(function (str) {
    this.saasStoreLoading = true;
    getSaasStoreList({saasStoreName: str, source: this.formData.source,pageNum: 1, pageSize: 10})
      .then((res) => {
        this.saasStoreLoading = false;
        this.allSaasStoreList = res.data;
      })
      .catch((err) => {});
  })

  // TODO: check 下拉框选择之后回显不出来，强制刷新就出来了
  saasChange($event) {
    this.$forceUpdate();
  }

  sassStoreTypeChange() {
    this.formData.saasStore = {};
  }

  authBandingFn() {
    if (!this.authCode) return;
    this.authBanding = true;
    const params = { authCode: this.authCode }
    authBandingApi(params).then((res) => {
      this.$message.success("授权成功")
    }).finally(() => {
      this.authBanding = false;
    })
  }

  getSaasList(str) {
    if (typeof str == "object") {
      str = "";
    }
    this.getSaasRemote(str);
  }

  dialogClose() {
    this.formData = cloneDeep(initFormData);
    this.validateFormRef.resetFields();
    this.storeMemberList = [];
    this.HomeInstance.tabRemove("storeFill");
  }

  dialogConfirm() {
    let parms: any = {};
    this.validateFormRef.validate((valid) => {
      if (valid) {
        parms = cloneDeep(this.formData);
        parms.userInfo = [];
        const { saaSStoreName = "", saaSStoreId = 0, brandId, zoneId } = parms.saasStore;
        parms.saasStoreName = saaSStoreName;
        parms.saasStoreId = saaSStoreId; // 清空的的时候传0
        parms.storeBindingNo = parms.storeBindingNo ?? "";
        Object.assign(parms, { saasBrandId: brandId, saasZoneId: zoneId })
        if (parms.address && parms.address.length > 1) {
          parms.address = parms.address.join(',');
        } else {
          parms.address = null; // 如果地址数组数据不全则设置为空
        }
        // 解析经纬度设置
        let lngAndLat = parms.longAndLat ? parms.longAndLat.split(',') : [];
        if (lngAndLat.length == 2) {
          let lat = lngAndLat[0];
          let lng = lngAndLat[1];
          // 数据精度控制在十位以内
          lat = lat.length > 8 ? lat.substring(0, 8) : lat;
          lng = lng.length > 8 ? lng.substring(0, 8) : lng;
          // 转换成数字类型存到数据库
          parms.storeLat = parseFloat(lat);
          parms.storeLong = parseFloat(lng);
          // 如果数字转换失败则置为空
          parms.storeLat = parms.storeLat ? parms.storeLat : null;
          parms.storeLong = parms.storeLong ? parms.storeLong : null;
        }

        parms.storeMentor = this.mentorTag ? this.mentorTag.id : 0;
        parms.storeSupport = this.supportTag ? this.supportTag.id : 0;

        this.dynamicTags.forEach((user) => {
          parms.userInfo.push({
            phone: user.phone,
            userId: user.id,
            nickname: user.nickname,
            icon: user.icon,
          });
        });
        parms.storeMemberList = this.storeMemberList;
        parms.restDateList = parms.restDateList?.map(el => (dayjs(el).format("YYYY-MM-DD 00:00:00"))) || []
        const api = this.formData.id ? editStore : createStore;
        this.saveLoading = true;
        api(parms).then(() => {
          this.dialogClose();
        }).finally(() => {
          this.saveLoading = false;
        })
      } else {
        return false;
      }
    });
  }
}
